import { Layout, SeoHead } from '@components/common'
import HomeJson from '@components/common/HomeJson'
import getGlobalStaticProps from '@lib/globalStaticProps'
// import { getAllProducts } from '@commerce/api/operations'
import { getDocByUid, prismicPreviewData } from '@lib/prismic'
import SliceZone from 'containers/SliceZone'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import NextHead from 'next/head'

export async function getStaticProps({
  preview,
  previewData = {},
  locale,
}: GetStaticPropsContext | prismicPreviewData) {
  const { ref } = previewData

  const globalProps = await getGlobalStaticProps(ref, locale)

  let doc = null

  try {
    const results = await Promise.all([getDocByUid('general', 'homepage', ref, locale)])
    // if current locale data not avaialbe try from prismic master language
    doc = results[0]
  } catch (err) {
    console.log(err)
  }
  return {
    props: {
      ...globalProps.props,
      doc: doc?.data || null,
      uid: doc?.uid || null,
      preview: preview || false,
    },
    revalidate: 86400, //24 hours,
  }
}

export default function Home({ doc }: InferGetStaticPropsType<typeof getStaticProps>) {
  const { body1, seo_title, seo_description, seo_image } = doc || {}

  return (
    <>
      <NextHead>
        <meta name="google-site-verification" key="datalayer" content="_Z60pBWXm1UkiXI0yLuZnM5Kj5l9NtOHWx-Q5bBL-Jc" />
      </NextHead>

      <SeoHead title={seo_title} description={seo_description} image={seo_image} />
      <SliceZone slices={body1} />
      <HomeJson />
    </>
  )
}

Home.Layout = Layout
