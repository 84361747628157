import { NextSeo } from 'next-seo'
import seoConfig from '@config/seo.json'
import { useRouter } from 'next/router'
import processLocale from '@lib/locale'

interface seoProps {
  title: string
  description: string
  image?: any
}
const SeoHead = ({ title, description, image }: seoProps) => {
  const { locale } = useRouter()
  const router = useRouter()
  const currentLocale = processLocale(locale)
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        type: 'website',
        title: title,
        description: description,
        url: `${seoConfig.openGraph.url}${currentLocale}${router.asPath}`,
        images: [
          {
            url: image?.url! || seoConfig.openGraph.images[0].url,
            width: 800,
            height: 600,
            alt: title,
          },
        ],
      }}
    />
  )
}

export default SeoHead
