import processLocale, { getFullCountryName } from '@lib/locale'
import { SiteLinksSearchBoxJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import seoConfig from '@config/seo.json'
import Head from 'next/head'



const HomeJson = () => {
  const { locale } = useRouter()
  const currentLocale = processLocale(locale)
  const url = `${seoConfig.openGraph.url}${currentLocale}`

  const potentialAction = [
    {
      target: `${url}/search?type=product&q=`,
      queryInput: 'search',
    },
  ]

  const organisation = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: `Sheet Society ${getFullCountryName(currentLocale)}`,
    url,
    sameAs: [
      'https://www.facebook.com/sheetsociety/',
      'https://www.youtube.com/c/sheetsociety',
      'https://www.instagram.com/sheetsociety/',
    ],
    contactPoint: [
      {
        '@type': 'ContactPoint',
        telephone: '+61 (03) 9917 3040',
        contactType: 'customer service',
      },
    ],
  }
  return (
    <>
      <SiteLinksSearchBoxJsonLd url={url} potentialActions={potentialAction} />
      <Head>
        <script
          id="json-organisation-corporate-contact"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(organisation) }}
        />
      </Head>
    </>
  )
}

export default HomeJson
